var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "box" } },
    [
      _c("div", { staticClass: "separate" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "a-card",
              {
                staticStyle: {
                  "box-sizing": "border-box",
                  "border-radius": "0 !important",
                },
              },
              [
                !_vm.appId
                  ? _c("a-divider", [_vm._v("请选择应用APPID")])
                  : _vm.noConfigText
                  ? _c("a-divider", [_vm._v("您尚未配置任何支付方式")])
                  : _vm._e(),
                _vm.payTestShow()
                  ? _c(
                      "div",
                      {
                        staticClass: "paydemo",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "paydemo-type-content",
                            staticStyle: { "min-height": "650px" },
                          },
                          [
                            _c(
                              "a-form-model",
                              {
                                ref: "form",
                                attrs: { model: _vm.form, rules: _vm.rules },
                                on: { submit: _vm.handleSubmit },
                              },
                              [
                                _c(
                                  "a-row",
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      colon: false,
                                                      "label-col": { span: 2 },
                                                      "wrapper-col": {
                                                        span: 15,
                                                        offset: 1,
                                                      },
                                                      label: "购买项目",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a-radio-group",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "-30px",
                                                        },
                                                        attrs: {
                                                          "default-value": 1,
                                                          name: "radioGroup",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-radio",
                                                          {
                                                            attrs: { value: 1 },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "年服务费充值"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#f39800",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "￥" +
                                                                    _vm._s(
                                                                      _vm.tobonumber
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      colon: false,
                                                      labelCol: { span: 4 },
                                                      "wrapper-col": {
                                                        span: 1,
                                                        offset: 1,
                                                      },
                                                      label: "购买年限",
                                                    },
                                                  },
                                                  [
                                                    _c("a-input-number", {
                                                      attrs: {
                                                        id: "inputNumber",
                                                        max: 10,
                                                        min: 1,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.totalnum,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "totalnum",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.totalnum",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              {
                                                attrs: { span: 12, pull: "4" },
                                              },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      colon: false,
                                                      labelCol: { span: 4 },
                                                      wrapperCol: {
                                                        span: 10,
                                                        offset: 1,
                                                      },
                                                      label: "购买单位",
                                                      prop: "company",
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        placeholder:
                                                          "请输入购买单位全称",
                                                      },
                                                      model: {
                                                        value: _vm.form.company,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "company",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.company",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      label: "信用代码",
                                                      prop: "yscode",
                                                      labelCol: { span: 6 },
                                                      wrapperCol: {
                                                        span: 13,
                                                        offset: 3,
                                                      },
                                                      colon: false,
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      staticStyle: {
                                                        "margin-left": "-30px",
                                                      },
                                                      attrs: {
                                                        disabled: true,
                                                        placeholder:
                                                          "请输入统一社会信用代码",
                                                      },
                                                      model: {
                                                        value: _vm.form.yscode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "yscode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.yscode",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 9 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "text-align": "left",
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      label: "证书卡号",
                                                      labelCol: { span: 5 },
                                                      wrapperCol: {
                                                        span: 14,
                                                        offset: 2,
                                                      },
                                                      colon: false,
                                                      required: "true",
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "margin-left": "-15px",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请点击识别卡号",
                                                        disabled: true,
                                                      },
                                                      model: {
                                                        value: _vm.keysn,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.keysn = $$v
                                                        },
                                                        expression: "keysn",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 4 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      labelCol: { span: 4 },
                                                      wrapperCol: {
                                                        span: 10,
                                                        offset: 0,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "shibie",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.handleSubmitmunber,
                                                        },
                                                      },
                                                      [_vm._v("识别证书卡号")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      colon: false,
                                                      labelCol: { span: 4 },
                                                      wrapperCol: {
                                                        span: 9,
                                                        offset: 1,
                                                      },
                                                      label: "联系人",
                                                      prop: "name",
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        placeholder:
                                                          "请输入联系人姓名",
                                                      },
                                                      model: {
                                                        value: _vm.form.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form.name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              {
                                                attrs: { span: 12, pull: "4" },
                                              },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      colon: false,
                                                      labelCol: { span: 4 },
                                                      wrapperCol: {
                                                        span: 10,
                                                        offset: 1,
                                                      },
                                                      label: "手机号码",
                                                      prop: "phone",
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        placeholder:
                                                          "请输入11位手机号码",
                                                      },
                                                      model: {
                                                        value: _vm.form.phone,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "phone",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.phone",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "a-row",
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        colon: false,
                                                        labelCol: { span: 2 },
                                                        wrapperCol: {
                                                          span: 8,
                                                          offset: 1,
                                                        },
                                                        label: "开票类型",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "baseline",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-radio-group",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "8px",
                                                              },
                                                              attrs: {
                                                                "default-value": 2,
                                                                name: "radioGroup",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .drawer,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "drawer",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.drawer",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a-tooltip",
                                                                {
                                                                  attrs: {
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "template",
                                                                    {
                                                                      slot: "title",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "发票一到三个工作日，会发到您填写的邮箱"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.form.drawer == 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "-30px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 仅支持电子增值税普通发票 "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.form.drawer == 2
                                                            ? _c("div", {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-row",
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        colon: false,
                                                        labelCol: { span: 6 },
                                                        wrapperCol: {
                                                          span: 16,
                                                          offset: 1,
                                                        },
                                                        label: "电子邮箱",
                                                        prop: "email",
                                                      },
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          disabled: true,
                                                          placeholder:
                                                            "请输入用于接收电子发票和查询订单的邮箱",
                                                        },
                                                        model: {
                                                          value: _vm.form.email,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "email",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.email",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        colon: false,
                                                        labelCol: { span: 6 },
                                                        wrapperCol: {
                                                          span: 15,
                                                          offset: 1,
                                                        },
                                                        label: "购方名称",
                                                      },
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            _vm.form.company,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "company",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.company",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 8, pull: "1" },
                                                },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        colon: false,
                                                        labelCol: {
                                                          span: 6,
                                                          offset: 2,
                                                        },
                                                        wrapperCol: {
                                                          span: 15,
                                                          offset: 1,
                                                        },
                                                        label: "购方税号",
                                                      },
                                                    },
                                                    [
                                                      _c("a-input", {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            _vm.form.yscode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "yscode",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.yscode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticStyle: { height: "60px" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-row",
                                  {},
                                  [
                                    _c("a-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "800px",
                                            margin: "auto",
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-form",
                                            {
                                              staticStyle: {
                                                "margin-left": "248px",
                                              },
                                              attrs: {
                                                id: "ok",
                                                form: _vm.form,
                                                "label-col": { span: 5 },
                                                "wrapper-col": { span: 12 },
                                              },
                                            },
                                            [
                                              _c(
                                                "a-form-item",
                                                { attrs: { label: "" } },
                                                [
                                                  _c("a-checkbox", {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    on: {
                                                      change: _vm.onChangeqw,
                                                    },
                                                    model: {
                                                      value: _vm.checked,
                                                      callback: function ($$v) {
                                                        _vm.checked = $$v
                                                      },
                                                      expression: "checked",
                                                    },
                                                  }),
                                                  _vm._v(" 我已认真阅读并 "),
                                                  _c(
                                                    "span",
                                                    {
                                                      style: _vm.radioStyle,
                                                      on: {
                                                        click: _vm.boglook,
                                                      },
                                                    },
                                                    [_vm._v("同意协议")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.companyinfostatus
                                  ? _c(
                                      "a-button",
                                      {
                                        staticClass: "shibie",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          width: "200px",
                                          height: "40px",
                                          background: "rgb(243, 152, 0)",
                                          "margin-left": "0px",
                                          "font-size": "15px",
                                        },
                                        attrs: { type: "danger" },
                                        on: { click: _vm.login },
                                      },
                                      [_vm._v("登录后提交订单")]
                                    )
                                  : _vm._e(),
                                !_vm.companyinfostatus
                                  ? _c(
                                      "a-button",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          width: "200px",
                                          height: "40px",
                                          background: "rgb(243, 152, 0)",
                                          "font-size": "15px",
                                        },
                                        attrs: {
                                          disabled: _vm.disabledlook,
                                          type: "danger",
                                        },
                                        on: { click: _vm.handleSubmit },
                                      },
                                      [_vm._v(" 提交订单 ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { attrs: { id: "sealintro" } }, [
                              _c("div", [
                                _vm._v(" 因本服务的消费特殊性，一经售出，"),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f39800" } },
                                  [_vm._v("无法退款")]
                                ),
                                _vm._v("，敬请理解！ "),
                              ]),
                            ]),
                            _c("div", {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                              attrs: { id: "bottomtoolbar" },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "a-modal",
              {
                attrs: { title: "流程说明", width: "40%" },
                on: { ok: _vm.handleOkmodd },
                model: {
                  value: _vm.visiblemodd,
                  callback: function ($$v) {
                    _vm.visiblemodd = $$v
                  },
                  expression: "visiblemodd",
                },
              },
              [
                _c("p", [
                  _c("b", [
                    _vm._v(
                      "灌装流程说明：企业在此下单后，前往证书运维管理平台上自助抠账使用。"
                    ),
                  ]),
                ]),
                _c("p", [_vm._v("Some contents...")]),
                _c("p", [_vm._v("Some contents...")]),
              ]
            ),
            _c("pay-test-modal", {
              ref: "payTestModal",
              on: {
                closeBarCode: function ($event) {
                  _vm.$refs.payTestBarCode.visible = false
                },
              },
            }),
            _c("pay-test-bar-code", {
              ref: "payTestBarCode",
              on: {
                CodeAgainChange: _vm.testCodeChange,
                barCodeValue: _vm.barCodeChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            "confirm-loading": _vm.confirmLoading,
            destroyOnClose: true,
            maskClosable: false,
            visible: _vm.visible,
            title: "阅读条款",
            width: "45%",
          },
          on: { cancel: _vm.handleCancel },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      staticClass: "footerbutton",
                      staticStyle: {
                        background: "#F39800",
                        "border-color": "#F39800",
                      },
                      attrs: { disabled: _vm.disabled, type: "primary" },
                      on: { click: _vm.handleOk },
                    },
                    [_vm._v(_vm._s(_vm.valiBtn) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("Contract", { attrs: { Fathertoson: _vm.Fathertoson } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }